import React from 'react'
import { graphql } from 'gatsby'
import { Layout } from 'components'
import { Hero, Body } from 'slices/news'

export const query = graphql`
  query newsQuery($uid: String!) {
    prismic {
      news(uid: $uid, lang: "en-us") {
        title
        hero_image
        published_date
        breadcrumb_title
        hero_title
        body {
          ... on PRISMIC_NewsBodyBody {
            type
            fields {
              body_image
              body_title
              body_content
              image_placement
            }
          }
        }
      }
    }
  }
`

const News = ({
  data: {
    prismic: { news },
  },
}) => {
  return (
    <Layout>
      <Hero data={news} />
      {news.body.map((slice, idx) => {
        switch (slice.type) {
          case 'body':
            return <Body key={idx} data={slice} />
          default:
            return null
        }
      })}
    </Layout>
  )
}

News.query = query

export default News
