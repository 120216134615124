import React from 'react'
import PrismicDOM from 'prismic-dom'
import system from '@rebass/components'
import { Box, Flex, Text, Image as ImageBase } from 'rebass'
import { fonts, colors } from 'theme'
import { ContentContainer } from 'components'

const Title = system(
  {
    extend: Text,
    fontFamily: fonts.main,
    fontSize: '24px',
    lineHeight: '1.25',
    color: '#151515',
    mb: '24px',
  },
  'space',
  {
    letterSpacing: '0.05em',
  },
)

const Content = system(
  {
    extend: Box,
    flex: 1,
  },
  'flex',
  {
    '&>*': {
      letterSpacing: '0.05em',
      color: colors.lightBlack,
      lineHeight: '1.47',
      fontSize: '15px',
    },
    p: {
      marginTop: '0px',
    },
  },
)

const Image = system(
  {
    extend: ImageBase,
    mb: '24px',
  },
  'width',
  'space',
  p => ({
    width: p.full ? '100%' : '40%',
    objectFit: p.full ? 'inherit' : 'contain',
  })
)

export const Body = ({ data }) => {
  const { fields } = data

  const renderBodyContent = content => (
    <Content dangerouslySetInnerHTML={{ __html: PrismicDOM.RichText.asHtml(content) }} />
  )

  const renderBodyContentWithImage = (content, image, placement) => (
    <Flex alignItems="flex-start" flexDirection={['column', 'row']}>
      {placement === 'Left' && <Image pr="20px" src={image} />}
      {renderBodyContent(content)}
      {placement === 'Right' && <Image pl="20px" src={image} />}
    </Flex>
  )

  return (
    <ContentContainer pt="0px">
      {fields.map((item, idx) => {
        const { body_image, body_title, body_content, image_placement } = item
        return (
          <Box key={idx}>
            {body_title && <Title>{PrismicDOM.RichText.asText(body_title)}</Title>}
            {body_image && image_placement === 'Top' && <Image src={body_image.url} />}
            {body_content && image_placement !== 'Left' && image_placement !== 'Right'
              ? renderBodyContent(body_content)
              : null}
            {body_content && (image_placement === 'Left' || image_placement === 'Right')
              ? renderBodyContentWithImage(body_content, body_image.url, image_placement)
              : null}
            {body_image && image_placement === 'Bottom' && <Image src={body_image.url} />}
          </Box>
        )
      })}
    </ContentContainer>
  )
}
